<script>

  export default {
    props: {
      liquidacionPersonaId: {
        required: true
      }
    },
    data() {
      return {
        cuerpoDeConsulta: undefined,
        persona: undefined,
        nominaLiquidacionPersona: undefined,
        resultConsultaAportesEnLinea: undefined,
        waitingConsulta: false,
      }
    },
    async mounted() {
      this.waitingConsulta = true
      const path = `LiquidadorNomina/GetCuerpoConsultaLiquidacionNominaAportesEnLinea/${this.liquidacionPersonaId}`
      const response = await this.$store.getters.fetchGet({ path })
      const { body, persona, nominaLiquidacionPersona } = await response.json()
      this.cuerpoDeConsulta = body
      this.persona = persona
      const { empresa } = JSON.parse(localStorage.getItem('setPersonaAct'))

      this.nominaLiquidacionPersona = nominaLiquidacionPersona

      if (nominaLiquidacionPersona.estadoAportes == 'Pendiente' && empresa.usarAportesEnLinea) {
        await this.consultarPersonaEnAportes()
      }
      this.waitingConsulta = false
    },
    methods: {

      async consultarPersonaEnAportes() {

        const token = await this.$store.getters.getTokenAportes()
        const response = await fetch(`${this.$store.state.APIAportes}/NominaElectronica/ConsultaEstado`, {
          method: 'POST',
          headers: {
            'accept': 'application/json',
            'token': token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.cuerpoDeConsulta)
        })
        const result = await response.json()

        if (result.estado == "En validación") {
          const responseSaveing = await this.$store.getters.fetchPost({
            path: `LiquidadorNomina/SaveResultConsultaAportesEnLineaIndividual/${this.liquidacionPersonaId}`,
            data: {
              trabajador: result
            }
          })
          if (responseSaveing.ok) {
            console.log('ok')
          } else {
            console.log('nop')
          }
        } else {
          const responseSaveing = await this.$store.getters.fetchPost({
            path: `LiquidadorNomina/SaveResultConsultaAportesEnLineaIndividual/${this.liquidacionPersonaId}`,
            data: result
          })
          if (responseSaveing.ok) {
            console.log('ok')
          } else {
            console.log('nooo')
          }
        }
        this.resultConsultaAportesEnLinea = result
      },
    }
  }

</script>

<template>
  <div class="container-fluid">
    <b-card style="min-height: 250px">
      <div v-if="nominaLiquidacionPersona && nominaLiquidacionPersona.estadoAportes == 'Pendiente'">
        <div>
          <h5 class="mb-1">
            La nomina de
            {{ `${nominaLiquidacionPersona.persona.nombres} ${nominaLiquidacionPersona.persona.apellidos}` }}:
          </h5>
        </div>
        <p>Esta en estado pendiente</p>
        <i class="fas fa-solid fa-clock text-warn" style='font-size: 25px;'
          v-if="nominaLiquidacionPersona.estadoAportes == 'Pendiente'"></i>
      </div>
      <div v-else-if="nominaLiquidacionPersona && nominaLiquidacionPersona.estadoAportes == 'Pendiente'">
        <h5 class="mb-1">
          La nomina de
          {{ `${nominaLiquidacionPersona.persona.nombres} ${nominaLiquidacionPersona.persona.apellidos}` }}:
        </h5>
        <p class="mb-3 d-flex align-items-center" style='font-size: 17px;'>
          esta en estado de
          {{ nominaLiquidacionPersona.estadoAportes }}
        </p>
        <i class="fas fa-solid fa-exclamation-triangle px-1 text-danger pointer"> </i>
      </div>
      <div v-else-if="nominaLiquidacionPersona && nominaLiquidacionPersona.estadoAportes == 'Rechazado'">
        <h5 class="mb-1">
          La nomina de
          {{ `${nominaLiquidacionPersona.persona.nombres} ${nominaLiquidacionPersona.persona.apellidos}` }}:
        </h5>
        <p class="mb-3 d-flex align-items-center" style='font-size: 17px;'>
          esta nomina fue rechazada
        </p>
        <i if="resultConsultaAportesEnLinea?.trabajador[0].mensajes[0].tipo == 'Rechazado'"
          class="fas fa-solid fa-check px-1 text-danger"></i>
      </div>
      <div v-else>
        <h5 class="mb-1">
          La nomina de
          {{ `${nominaLiquidacionPersona.persona.nombres} ${nominaLiquidacionPersona.persona.apellidos}` }}:
        </h5>
        <p class="mb-3 d-flex align-items-center" style='font-size: 17px;'>
          Esta nomin no ha sido transmitida
          <span>
            <i class="fas fa-solid fa-minus px-1" style="color: gray; font-size: 25px;"></i>
          </span>
        </p>
      </div>
    </b-card>
  </div>
</template>